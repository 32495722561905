import { useReducer } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import PrivateRoute from "../routes/PrivateRoutes";
import { initialAuth, authReducer } from "../reducer/auth";
import { initialAlert, alertReducer } from "../reducer/alert";
import { initialLoading, loadingReducer } from "../reducer/loading";
import { AuthContext, AlertContext, LoadingContext } from "../context/index";

import Login from "./auth/Login";
import Home from "./views/home/Home";
import AlertBox from "./common/AlertBox";
import LoadingBox from "./common/LoadingBox";

import { CLIENTS } from "../constants/value";

import Pause from "./views/base/Pause";
import Refill from "./views/base/Refill";
import Restore from "./views/base/Restore";
import Unpause from "./views/base/Unpause";
import Activate from "./views/base/Activate";
import ChangePlan from "./views/base/ChangePlan";
import Deactivate from "./views/base/Deactivate";
import ChangeNumber from "./views/base/ChangeNumber";

import ActivateForm from "./views/Ultra/Activate/ActivateForm";
import ActivateBatch from "./views/Ultra/Activate/ActivateBatch";
import ActivateHistory from "./views/Ultra/Activate/ActivateHistory";

import ChangeNumberForm from "./views/Ultra/ChangeNumber/ChangeNumberForm";
import ChangeNumberBatch from "./views/Ultra/ChangeNumber/ChangeNumberBatch";
import ChangeNumberHistory from "./views/Ultra/ChangeNumber/ChangeNumberHistory";

import PauseForm from "./views/Ultra/Pause/PauseForm";
import PauseBatch from "./views/Ultra/Pause/PauseBatch";
import PauseHistory from "./views/Ultra/Pause/PauseHistory";

import DeactivateForm from "./views/Ultra/Deactivate/DeactivateForm";
import DeactivateBatch from "./views/Ultra/Deactivate/DeactivateBatch";
import DeactivateHistory from "./views/Ultra/Deactivate/DeactivateHistory";

import RestoreForm from "./views/Ultra/Restore/RestoreForm";
import RestoreBatch from "./views/Ultra/Restore/RestoreBatch";
import RestoreHistory from "./views/Ultra/Restore/RestoreHistory";

import SimInfo from "./views/base/SimInfo";
import SimInfoForm from "./views/Ultra/SimInfo/SimInfoForm";
import SimInfoBatch from "./views/Ultra/SimInfo/SimInfoBatch";

import TelitActivateForm from "./views/Telit/Activate/ActivateForm";
import TelitActivateBatch from "./views/Telit/Activate/ActivateBatch";
import TelitActivateHistory from "./views/Telit/Activate/ActivateHistory";

import TelitDeactivateForm from "./views/Telit/Deactivate/DeactivateForm";
import TelitDeactivateBatch from "./views/Telit/Deactivate/DeactivateBatch";
import TelitDeactivateHistory from "./views/Telit/Deactivate/DeactivateHistory";

import TelitChangeNumberForm from "./views/Telit/ChangeNumber/ChangeNumberForm";
import TelitChangeNumberBatch from "./views/Telit/ChangeNumber/ChangeNumberBatch";
import TelitChangeNumberHistory from "./views/Telit/ChangeNumber/ChangeNumberHistory";

import ChangePlanForm from "./views/Ultra/ChangePlan/ChangePlanForm";
import ChangePlanBatch from "./views/Ultra/ChangePlan/ChangePlanBatch";
import ChangePlanHistory from "./views/Ultra/ChangePlan/ChangePlanHistory";

import TelitExportList from "./views/Telit/SimInfo/TelitExportList";
import TelitChangePlanForm from "./views/Telit/ChangePlan/ChangePlanForm";
import TelitChangePlanBatch from "./views/Telit/ChangePlan/ChangePlanBatch";
import TelitChangePlanHistory from "./views/Telit/ChangePlan/ChangePlanHistory";

import ZipCheckList from "./views/Ultra/SimInfo/ZipChecks";
import ZipCheckForm from "./views/Ultra/SimInfo/ZipCheckForm";

import UnpauseForm from "./views/Ultra/Unpause/UnpauseForm";
import UnpauseBatch from "./views/Ultra/Unpause/UnpauseBatch";
import UnpauseHistory from "./views/Ultra/Unpause/UnpauseHistory";

import SparqActivateForm from "./views/Sparq/Activate/ActivateForm";
import SparqActivateBatch from "./views/Sparq/Activate/ActivateBatch";
import SparqActivateHistory from "./views/Sparq/Activate/ActivateHistory";

import SparqDeactivateForm from "./views/Sparq/Deactivate/DeactivateForm";
import SparqDeactivateBatch from "./views/Sparq/Deactivate/DeactivateBatch";
import SparqDeactivateHistory from "./views/Sparq/Deactivate/DeactivateHistory";

import CricketActivateForm from "./views/Cricket/Activate/ActivateForm";
import CricketActivateHistory from "./views/Cricket/Activate/ActivateHistory";
import CricketActivateBatch from "./views/Cricket/Activate/ActivateBatch";

import VerizonActivateForm from "./views/Verizon/Activate/ActivateForm";
import VerizonActivateHistory from "./views/Verizon/Activate/ActivateHistory";
import VerizonActivateBatch from "./views/Verizon/Activate/ActivateBatch";

import CricketRefillForm from "./views/Cricket/Refill/RefillForm";
import CricketRefillHistory from "./views/Cricket/Refill/RefillHistory";
import CricketRefillBatch from "./views/Cricket/Refill/RefillBatch";

import VerizonRefillForm from "./views/Verizon/Refill/RefillForm";
import VerizonRefillHistory from "./views/Verizon/Refill/RefillHistory";
import VerizonRefillBatch from "./views/Verizon/Refill/RefillBatch";

import TelitPauseForm from "./views/Telit/Pause/PauseForm";
import TelitPauseBatch from "./views/Telit/Pause/PauseBatch";
import TelitPauseHistory from "./views/Telit/Pause/PauseHistory";

import CredentialForm from "./views/Ultra/CredentialForm";
import TelitCredentialForm from "./views/Telit/CredentialForm";
import SparqCredentialForm from "./views/Sparq/CredentialForm";
import VerizonCredentialForm from "./views/Verizon/CredentialForm";
import CricketCredentialForm from "./views/Cricket/CredentialForm";

import Portin from "./views/base/Portin";
import PortinForm from "./views/Ultra/Portin/PortinForm";
import PortinHistory from "./views/Ultra/Portin/PortinHistory";
import PortinBatch from "./views/Ultra/Portin/PortinBatch";

import TelitSimInfoForm from "./views/Telit/SimInfo/SimInfoForm";
import TelitSimInfoBatch from "./views/Telit/SimInfo/SimInfoBatch";
import AdminRoutes from "../routes/AdminRoutes";

import ClientActivateForm from "./views/Client/Activate/ActivateForm";
import ClientActivateBatch from "./views/Client/Activate/ActivateBatch";
import ClientDeactivateForm from "./views/Client/Deactivate/DeactivateForm";
import ClientDeactivateBatch from "./views/Client/Deactivate/DeactivateBatch";
import ClientChangeNumberForm from "./views/Client/ChangeNumber/ChangeNumberForm";
import ClientChangeNumberBatch from "./views/Client/ChangeNumber/ChangeNumberBatch";

import User from "./views/User/User";
import UserUpdate from "./views/User/UserUpdate";
import UserCreate from "./views/User/UserCreate";
import HistoryForm from "./views/User/HistoryForm";

import ClientSimInfoForm from "./views/Client/SimInfo/SimInfoForm";
import ClientSimInfoBatch from "./views/Client/SimInfo/SimInfoBatch";
import ClientSimInfo from "./views/Client/SimInfo/SimInfo";

import ResetForm from "./views/Reset/ResetForm";
import ResetBatch from "./views/Reset/ResetBatch";
import ResetBatchList from "./views/Reset/ResetBatchList";

import TMobileCredentialForm from "./views/TMobile/CredentialForm";
import TMobileActivateForm from "./views/TMobile/Activate/ActivateForm";
import TMobileActivateBatch from "./views/TMobile/Activate/ActivateBatch";
import TMobileActivateHistory from "./views/TMobile/Activate/ActivateHistory";

import SelectelSimInfoBatch from "./views/Selectel/SimInfo/SimInfoBatch";
import SelectelSimInfoForm from "./views/Selectel/SimInfo/SimInfoForm";
import SelectelCredentialForm from "./views/Selectel/CredentialForm";

import Hotline from "./views/base/Hotline";
import HotlineForm from "./views/Selectel/Hotline/HotlineForm";
import HotlineHistory from "./views/Selectel/Hotline/HotlineHistory";
import HotlineBatch from "./views/Selectel/Hotline/HotlineBatch";

function App() {
  const [auth, authDispatch] = useReducer(authReducer, initialAuth);
  const [alert, alertDispatch] = useReducer(alertReducer, initialAlert);
  const [isLoading, loadingDispatch] = useReducer(
    loadingReducer,
    initialLoading
  );
  return (
    <BrowserRouter>
      <AuthContext.Provider value={[auth, authDispatch]}>
        <AlertContext.Provider value={[alert, alertDispatch]}>
          <LoadingContext.Provider value={[isLoading, loadingDispatch]}>
            <AlertBox />
            <LoadingBox />
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="/" element={<PrivateRoute />}>
                <Route index element={<Home />} />

                <Route path="/" element={<AdminRoutes />}>
                  <Route path="/ultra">
                    <Route index element={<Navigate to="activate" />} />
                    <Route path="credential" element={<CredentialForm />} />
                    <Route path="activate">
                      <Route
                        index
                        element={
                          <Activate
                            key={CLIENTS.ultra}
                            client={CLIENTS.ultra}
                          />
                        }
                      />
                      <Route path="form" element={<ActivateForm />} />
                      <Route
                        path=":activate_id"
                        element={<ActivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<ActivateBatch />}
                      />
                    </Route>
                    <Route path="deactivate">
                      <Route
                        index
                        element={
                          <Deactivate
                            key={CLIENTS.ultra}
                            client={CLIENTS.ultra}
                          />
                        }
                      />
                      <Route path="form" element={<DeactivateForm />} />
                      <Route
                        path=":deactivate_id"
                        element={<DeactivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<DeactivateBatch />}
                      />
                    </Route>
                    <Route path="change_number">
                      <Route
                        index
                        element={
                          <ChangeNumber
                            key={CLIENTS.ultra}
                            client={CLIENTS.ultra}
                          />
                        }
                      />
                      <Route path="form" element={<ChangeNumberForm />} />
                      <Route
                        path=":change_number_id"
                        element={<ChangeNumberHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<ChangeNumberBatch />}
                      />
                    </Route>
                    <Route path="change_plan">
                      <Route
                        index
                        element={
                          <ChangePlan
                            key={CLIENTS.ultra}
                            client={CLIENTS.ultra}
                          />
                        }
                      />
                      <Route path="form" element={<ChangePlanForm />} />
                      <Route
                        path=":change_plan_id"
                        element={<ChangePlanHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<ChangePlanBatch />}
                      />
                    </Route>
                    <Route path="pause">
                      <Route
                        index
                        element={
                          <Pause key={CLIENTS.ultra} client={CLIENTS.ultra} />
                        }
                      />
                      <Route path="form" element={<PauseForm />} />
                      <Route path=":pause_id" element={<PauseHistory />} />
                      <Route path="batch/:batch_id" element={<PauseBatch />} />
                    </Route>
                    <Route path="deactivate">
                      <Route
                        index
                        element={
                          <Deactivate
                            client={CLIENTS.ultra}
                            key={CLIENTS.ultra}
                          />
                        }
                      />
                      <Route path="form" element={<DeactivateForm />} />
                      <Route
                        path=":deactivate_id"
                        element={<DeactivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<DeactivateBatch />}
                      />
                    </Route>
                    <Route path="unpause">
                      <Route
                        index
                        element={
                          <Unpause key={CLIENTS.ultra} client={CLIENTS.ultra} />
                        }
                      />
                      <Route path="form" element={<UnpauseForm />} />
                      <Route path=":unpause_id" element={<UnpauseHistory />} />
                      <Route
                        path="batch/:batch_id"
                        element={<UnpauseBatch />}
                      />
                    </Route>
                    <Route path="restore">
                      <Route
                        index
                        element={
                          <Restore key={CLIENTS.ultra} client={CLIENTS.ultra} />
                        }
                      />
                      <Route path="form" element={<RestoreForm />} />
                      <Route path=":restore_id" element={<RestoreHistory />} />
                      <Route
                        path="batch/:batch_id"
                        element={<RestoreBatch />}
                      />
                    </Route>
                    <Route path="sim_info">
                      <Route
                        index
                        element={<SimInfo client={CLIENTS.ultra} />}
                      />
                      <Route path="form" element={<SimInfoForm />} />
                      <Route
                        path="batch/:batch_id"
                        element={<SimInfoBatch />}
                      />
                      <Route path="zip" element={<ZipCheckList />} />
                      <Route path="zip/form" element={<ZipCheckForm />} />
                    </Route>
                    <Route path="portin">
                      <Route
                        index
                        element={
                          <Portin key={CLIENTS.ultra} client={CLIENTS.ultra} />
                        }
                      />
                      <Route path="form" element={<PortinForm />} />
                      <Route path=":portin_id" element={<PortinHistory />} />
                      <Route path="batch/:batch_id" element={<PortinBatch />} />
                    </Route>
                    <Route path="reset">
                      <Route
                        index
                        element={
                          <ResetBatchList
                            key={CLIENTS.ultra}
                            client={CLIENTS.ultra}
                            isClient={false}
                          />
                        }
                      />
                      <Route
                        path="form"
                        element={
                          <ResetForm
                            key={CLIENTS.ultra}
                            client={CLIENTS.ultra}
                            isClient={false}
                          />
                        }
                      />
                      <Route path="batch/:batch_id" element={<ResetBatch />} />
                    </Route>
                  </Route>

                  <Route path="/telit">
                    <Route index element={<Navigate to="activate" />} />
                    <Route
                      path="credential"
                      element={<TelitCredentialForm />}
                    />
                    <Route path="activate">
                      <Route
                        index
                        element={
                          <Activate
                            client={CLIENTS.telit}
                            key={CLIENTS.telit}
                          />
                        }
                      />
                      <Route path="form" element={<TelitActivateForm />} />
                      <Route
                        path=":activate_id"
                        element={<TelitActivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<TelitActivateBatch />}
                      />
                    </Route>
                    <Route path="deactivate">
                      <Route
                        index
                        element={
                          <Deactivate
                            client={CLIENTS.telit}
                            key={CLIENTS.telit}
                          />
                        }
                      />
                      <Route path="form" element={<TelitDeactivateForm />} />
                      <Route
                        path=":deactivate_id"
                        element={<TelitDeactivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<TelitDeactivateBatch />}
                      />
                    </Route>
                    <Route path="change_number">
                      <Route
                        index
                        element={
                          <ChangeNumber
                            client={CLIENTS.telit}
                            key={CLIENTS.telit}
                          />
                        }
                      />
                      <Route path="form" element={<TelitChangeNumberForm />} />
                      <Route
                        path=":change_number_id"
                        element={<TelitChangeNumberHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<TelitChangeNumberBatch />}
                      />
                    </Route>
                    <Route path="change_plan">
                      <Route
                        index
                        element={
                          <ChangePlan
                            client={CLIENTS.telit}
                            key={CLIENTS.telit}
                          />
                        }
                      />
                      <Route path="form" element={<TelitChangePlanForm />} />
                      <Route
                        path=":change_plan_id"
                        element={<TelitChangePlanHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<TelitChangePlanBatch />}
                      />
                    </Route>
                    <Route path="pause">
                      <Route
                        index
                        element={
                          <Pause client={CLIENTS.telit} key={CLIENTS.telit} />
                        }
                      />
                      <Route path="form" element={<TelitPauseForm />} />
                      <Route path=":pause_id" element={<TelitPauseHistory />} />
                      <Route
                        path="batch/:batch_id"
                        element={<TelitPauseBatch />}
                      />
                    </Route>
                    <Route path="deactivate">
                      <Route
                        index
                        element={
                          <Deactivate
                            client={CLIENTS.telit}
                            key={CLIENTS.telit}
                          />
                        }
                      />
                      <Route path="form" element={<TelitDeactivateForm />} />
                      <Route
                        path=":deactivate_id"
                        element={<TelitDeactivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<TelitDeactivateBatch />}
                      />
                    </Route>
                    <Route path="sim_info">
                      <Route
                        index
                        element={<SimInfo client={CLIENTS.telit} />}
                      />
                      <Route path="form" element={<TelitSimInfoForm />} />
                      <Route
                        path="batch/:batch_id"
                        element={<TelitSimInfoBatch />}
                      />
                      <Route path="export" element={<TelitExportList />} />
                    </Route>
                    <Route path="reset">
                      <Route
                        index
                        element={
                          <ResetBatchList
                            key={CLIENTS.telit}
                            client={CLIENTS.telit}
                            isClient={false}
                          />
                        }
                      />
                      <Route
                        path="form"
                        element={
                          <ResetForm
                            key={CLIENTS.ultra}
                            client={CLIENTS.telit}
                            isClient={false}
                          />
                        }
                      />
                      <Route path="batch/:batch_id" element={<ResetBatch />} />
                    </Route>
                  </Route>

                  <Route path="/sparq">
                    <Route index element={<Navigate to="activate" />} />
                    <Route
                      path="credential"
                      element={<SparqCredentialForm />}
                    />
                    <Route path="activate">
                      <Route
                        index
                        element={
                          <Activate
                            client={CLIENTS.sparq}
                            key={CLIENTS.sparq}
                          />
                        }
                      />
                      <Route path="form" element={<SparqActivateForm />} />
                      <Route
                        path=":activate_id"
                        element={<SparqActivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<SparqActivateBatch />}
                      />
                    </Route>
                    <Route path="deactivate">
                      <Route
                        index
                        element={
                          <Deactivate
                            client={CLIENTS.sparq}
                            key={CLIENTS.sparq}
                          />
                        }
                      />
                      <Route path="form" element={<SparqDeactivateForm />} />
                      <Route
                        path=":deactivate_id"
                        element={<SparqDeactivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<SparqDeactivateBatch />}
                      />
                    </Route>
                  </Route>

                  <Route path="/cricket">
                    <Route index element={<Navigate to="activate" />} />
                    <Route
                      path="credential"
                      element={<CricketCredentialForm />}
                    />
                    <Route path="activate">
                      <Route
                        index
                        element={
                          <Activate
                            client={CLIENTS.cricket}
                            key={CLIENTS.cricket}
                          />
                        }
                      />
                      <Route path="form" element={<CricketActivateForm />} />
                      <Route
                        path=":activate_id"
                        element={<CricketActivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<CricketActivateBatch />}
                      />
                    </Route>
                    <Route path="refill">
                      <Route
                        index
                        element={
                          <Refill
                            client={CLIENTS.cricket}
                            key={CLIENTS.cricket}
                          />
                        }
                      />
                      <Route path="form" element={<CricketRefillForm />} />
                      <Route
                        path=":refill_id"
                        element={<CricketRefillHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<CricketRefillBatch />}
                      />
                    </Route>
                  </Route>

                  <Route path="/verizon">
                    <Route
                      index
                      element={<Navigate to="activate" key={CLIENTS.verizon} />}
                    />
                    <Route
                      path="credential"
                      element={<VerizonCredentialForm />}
                    />
                    <Route path="activate">
                      <Route
                        index
                        element={<Activate client={CLIENTS.verizon} />}
                      />
                      <Route path="form" element={<VerizonActivateForm />} />
                      <Route
                        path=":activate_id"
                        element={<VerizonActivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<VerizonActivateBatch />}
                      />
                    </Route>
                    <Route path="refill">
                      <Route
                        index
                        element={
                          <Refill
                            client={CLIENTS.verizon}
                            key={CLIENTS.verizon}
                          />
                        }
                      />
                      <Route path="form" element={<VerizonRefillForm />} />
                      <Route
                        path=":refill_id"
                        element={<VerizonRefillHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<VerizonRefillBatch />}
                      />
                    </Route>
                  </Route>
                  <Route path="/tmobile">
                    <Route
                      index
                      element={<Navigate to="activate" key={CLIENTS.tmobile} />}
                    />
                    <Route
                      path="credential"
                      element={<TMobileCredentialForm />}
                    />
                    <Route path="activate">
                      <Route
                        index
                        element={<Activate client={CLIENTS.tmobile} />}
                      />
                      <Route path="form" element={<TMobileActivateForm />} />
                      <Route
                        path=":activate_id"
                        element={<TMobileActivateHistory />}
                      />
                      <Route
                        path="batch/:batch_id"
                        element={<TMobileActivateBatch />}
                      />
                    </Route>
                  </Route>
                  <Route path="/selectel">
                    <Route path="hotline">
                      <Route
                        index
                        element={
                          <Hotline
                            key={CLIENTS.selectel}
                            client={CLIENTS.selectel}
                          />
                        }
                      />
                      <Route path="form" element={<HotlineForm />} />
                      <Route path=":hotline_id" element={<HotlineHistory />} />
                      <Route
                        path="batch/:batch_id"
                        element={<HotlineBatch />}
                      />
                    </Route>
                    <Route
                      path="credential"
                      element={<SelectelCredentialForm />}
                    />
                    <Route path="sim_info">
                      <Route
                        index
                        element={<SimInfo client={CLIENTS.selectel} />}
                      />
                      <Route path="form" element={<SelectelSimInfoForm />} />
                      <Route
                        path="batch/:batch_id"
                        element={<SelectelSimInfoBatch />}
                      />
                    </Route>
                  </Route>
                  <Route path="/user">
                    <Route index element={<User />} />
                    <Route path=":user_id" element={<UserUpdate />} />
                    <Route path="create" element={<UserCreate />} />
                    <Route path="user_history" element={<HistoryForm />} />
                  </Route>
                </Route>
                <Route path="/client">
                  <Route index element={<Navigate to="activate" />} />
                  <Route path="activate">
                    <Route index element={<Activate />} />
                    <Route path="form" element={<ClientActivateForm />} />
                    <Route
                      path="batch/:batch_id"
                      element={<ClientActivateBatch />}
                    />
                  </Route>
                  <Route path="deactivate">
                    <Route index element={<Deactivate />} />
                    <Route path="form" element={<ClientDeactivateForm />} />
                    <Route
                      path="batch/:batch_id"
                      element={<ClientDeactivateBatch />}
                    />
                  </Route>
                  <Route path="change_number">
                    <Route index element={<ChangeNumber />} />
                    <Route path="form" element={<ClientChangeNumberForm />} />
                    <Route
                      path="batch/:batch_id"
                      element={<ClientChangeNumberBatch />}
                    />
                  </Route>
                  <Route path="sim_info">
                    <Route index element={<ClientSimInfo />} />
                    <Route path="form" element={<ClientSimInfoForm />} />
                    <Route
                      path="batch/:batch_id"
                      element={<ClientSimInfoBatch />}
                    />
                  </Route>
                  <Route path="reset">
                    <Route index element={<ResetBatchList isClient={true} />} />
                    <Route
                      path="form"
                      element={<ResetForm isClient={true} />}
                    />
                    <Route path="batch/:batch_id" element={<ResetBatch />} />
                  </Route>
                </Route>

                <Route path="*" element={<h1>404 Error!!!</h1>} />
              </Route>
            </Routes>
          </LoadingContext.Provider>
        </AlertContext.Provider>
      </AuthContext.Provider>
    </BrowserRouter>
  );
}

export default App;
